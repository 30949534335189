import { handleError } from "./errorHandler";

export let birthDay;
export let birthMonth;
export let birthYear;

const validateBirthday = (
  day, 
  month, 
  year, 
  dayErrorContainer,
  monthErrorContainer,
  yearErrorContainer,
  dateErrorContainer,
) => {
  const getDayErrorMessage = (value) => {
    if ((value > 31) || (value < 1)) {
      return 'Día incorrecto';
    }
    return '';
  }
  const dayValue = Number(day);
	handleError(getDayErrorMessage(dayValue), dayErrorContainer);
  
  const getMonthValue = (value) => {
    switch (value) {
      case "Enero":
        return 1;
      case "Feb":
        return 2;
      case "Mar":
        return 3;
      case "Abr":
        return 4;
      case "Mayo":
        return 5;
      case "Jun":
        return 6;
      case "Jul":
        return 7;
      case "Agosto":
        return 8;
      case "Sept":
        return 9;
      case "Oct":
        return 10;
      case "Nov":
        return 11;
      case "Dic":
        return 12;
    }
  }

  const getMonthErrorMessage = (value) => {
    if ((value > 12) || (value < 1)) {
      return 'Mes incorrecto';
    }
    return '';
  }
  const monthValue = getMonthValue(month);
	handleError(getMonthErrorMessage(monthValue), monthErrorContainer);

  const getYearErrorMessage = (value) => {
    if ((value.length < 4) || (value > new Date().getFullYear()) || (value < 1920)) {
      return 'Año incorrecto';
    }
    return '';
  }
  const yearValue = Number(year);
	handleError(getYearErrorMessage(yearValue), yearErrorContainer);

  const daysInMonth = (m, y) => {
    switch (m) {
      case 2 :
        return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
      case 4 : case 6 : case 9 : case 11 :
        return 30;
      default :
        return 31
    }
  }
  const isValidDayOfMonth = (d, m, y) => {
    return m > 0 && m <= 12 && d > 0 && d <= daysInMonth(m, y);
  }
  const isValidCurrentDay = isValidDayOfMonth(dayValue, monthValue, yearValue);
  const dayInMonthError = (!isValidCurrentDay && day && month) ? 'Día inválido' : '';
	handleError(dayInMonthError, dayErrorContainer);

  const diffDatesOfYears = (birthDay) => {
    if (!birthDay) {
      return 'El cumpleaños es incorrecto';
    }
    const transformBirthDay = `${birthDay}`.split(".").
      map(date => date.length < 2 ? `0${date}` : date).reverse().join('-')
    const diffYears = Math.abs(new Date() - new Date(transformBirthDay));
    const millisecondInOneYear = 1000 * 60 * 60 * 24 * 365;
    const LEGAL_AGE = 18;
    const leapYearInMillisecond = 1000 * 60 * 60 * 24
    const isUnderLegalAge = ((diffYears - leapYearInMillisecond * 4) / millisecondInOneYear) < LEGAL_AGE;
  
    if (isUnderLegalAge || diffYears <= 0) {
      return 'La edad mínima para registrarse es de 18 años';
    }
  
    return '';
  }

  const checkUserAbove18 = () => {
    if (dayErrorContainer.innerText || monthErrorContainer.innerText || yearErrorContainer.innerText) {
      handleError('', dateErrorContainer);
      dateErrorContainer.classList.add('show');
    }
  
    if (!day || !month || !year || day?.length === 0 || month?.length === 0 || year?.length === 0) {
      handleError('', dateErrorContainer);
      dateErrorContainer.classList.add('show');
      return;
    }
    
    const dateErrorMessage = diffDatesOfYears(`${dayValue}.${monthValue}.${yearValue}`);
    handleError(dateErrorMessage, dateErrorContainer);
  }
  checkUserAbove18();
  birthDay = dayValue;
  birthMonth = monthValue;
  birthYear = yearValue;
}

export default validateBirthday;

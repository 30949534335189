const common = {
    en: {
        "common.default": "Default text",
        "common.button.ok": "OK",
    },
    es: {
        "common.default": "Texto predeterminado",
        "common.button.ok": "OK",
    },
};

export default common;
import {trackingQuery} from "../utils/trackingQueryHook";
import {internalSetCookie} from "../utils/cookies";

// tracking query params
const urlSearchParams = new URLSearchParams(window.location.search);

if (!Object.fromEntries) {
    Object.fromEntries = function (iterable) {
        // Assume the input is either an iterable object or an array-like object
        return Array.from(iterable).reduce(function (obj, entry) {
            // https://github.com/tc39/proposal-object-from-entries/blob/e4837799c1586a07c101570b27997497e5290c22/polyfill.js#L9-L10
            // contract is that entry has "0" and "1" keys, not that it is an array or iterable.
            obj[entry[0]] = entry[1];
            return obj;
        }, {});
    };
}

const params = Object.fromEntries(urlSearchParams.entries());
trackingQuery(params);
internalSetCookie('fullUrl', window.location.href);
localStorage.setItem('fullUrl', window.location.href);
sessionStorage.setItem('fullUrl', window.location.href);

import { openAppLink } from '../utils/getAppLink';
import { getMobileOperatingSystem } from '../utils/utils';
import { internalGetCookie } from '../utils/cookies';

const sidemenuLink = document.querySelector('.sidemenu .download__android');
if (sidemenuLink && (getMobileOperatingSystem() === 'iOS' || getMobileOperatingSystem() === 'MacOS')) {
  sidemenuLink.classList.add('download__ios');
  sidemenuLink.classList.remove('download__android');
  sidemenuLink.innerHTML = sidemenuLink.innerHTML.replace('android', 'ios');
}

const openLink = (element, needLinkForIOSApp = true) => {
  element.addEventListener('click', () => openAppLink(needLinkForIOSApp));
};

const onDownloadClick = () => {
  document.querySelectorAll('.download__android').forEach((appLink) => {
    openLink(appLink, false);
  });
  document.querySelectorAll('.download__ios').forEach((appLink) => {
    openLink(appLink, true);
  });
};

onDownloadClick();

// const handleAppPanel = () => {
//   const appPanel = document.getElementById('app__panel');
//   const linkButton = document.getElementById('app__panel__link');
//   const closeButton = document.getElementById('app__panel__close');
//
//   try {
//     const lastCloseAppPanel = localStorage.getItem('isAppPanelVisible');
//     if (lastCloseAppPanel && Date.now() - +lastCloseAppPanel < (1000 * 60 * 30)) {
//       appPanel.style.display = 'none';
//     }
//   } catch (e) {
//     // do nothing
//   }
//
//
//   if (internalGetCookie('mobile-app') === '1' || !(
//     getMobileOperatingSystem().toLowerCase() === 'ios' ||
//     getMobileOperatingSystem().toLowerCase() === 'android'
//   )) { // check if mobile app or not mobile device
//     if (appPanel.classList.contains('open')) {
//       appPanel.classList.remove('open');
//     }
//   } else {
//     if (!appPanel.classList.contains('open')) {
//       appPanel.classList.add('open');
//     }
//     openLink(linkButton, getMobileOperatingSystem().toLowerCase() === 'ios');
//     closeButton.addEventListener('click', () => {
//       localStorage.setItem('isAppPanelVisible', Date.now().toString());
//       appPanel.style.display = 'none';
//     });
//   }
// };
//
// handleAppPanel();
// Mobile App Banner is unused on home page
import translations from "../translations/_translations";
import { APP_MODE } from "../../../config";

const getMessage = (key, item) => {
	const locale = window.location.pathname === "/en" ? "en" : "es";
	const isTranslateExist = translations[locale][key];
	const translation = isTranslateExist ?? '';

	if (APP_MODE === 'dev') {
		if (!translations['en'][key]) {
			console.warn(`No 'en' locale translate for ${item.outerHTML}`);
		}
		if (!translations['es'][key]) {
			console.warn(`No 'es' locale translate for ${item.outerHTML}`);
		}
	}

	return translation;
};

export default getMessage;

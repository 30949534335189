import { SCREEN_WIDTH } from '../utils/consts';
import {sendGtagEvent} from "../utils/gtag";
import {getValueFromBrowser} from "../utils/trackingQueryHook";

const burgerButton = document.querySelector('.burger');
const sideMenu = document.querySelector('.sidemenu');
const overlay = document.querySelector('.overlay');
const exceptionalItems = document.querySelectorAll('#exceptional-item');

const toggle = (element) => {
  element?.classList.toggle('is__open');
}

const open = (element, className = 'is__open') => {
  if (!element?.classList.contains(className)) {
    element?.classList.add(className);
  }
}

const closeSideMenu = () => {
  burgerButton?.classList.remove('is__open');
  sideMenu?.classList.remove('is__open');
  overlay?.classList.remove('is__open');
}

burgerButton?.addEventListener('click', () => {
  toggle(burgerButton);
  toggle(sideMenu);
  sendGtagEvent(sideMenu?.classList?.contains('is__open') ? 'sidemenu_opened' : 'sidemenu_closed', {
    affiliate_id: getValueFromBrowser('affiliateId') || '',
  });
});

exceptionalItems.forEach((accordion) => {
  accordion.addEventListener('click', () => {
    if (!sideMenu?.classList.contains('is__open')) {
      open(burgerButton);
      open(sideMenu);
      open(accordion, 'open');
    }
  })
})

overlay?.addEventListener('click', closeSideMenu);

let resize;
window.addEventListener('resize', () => {
  clearTimeout(resize);
  resize = setTimeout(() => {
    if (window.innerWidth > SCREEN_WIDTH.DESKTOP_S) {
      closeSideMenu();
    }   
  }); 
});

const gtag = document.querySelectorAll('[data-gtag]');

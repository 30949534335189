import getMessage from "../utils/getMessage";

const getAllTranslations = () => {
    document.documentElement.lang = navigator.language;
    document.querySelectorAll('[data-get-message]').forEach((item) => {
        item.innerText = getMessage(item.dataset.getMessage, item);
    });
    document.querySelectorAll('[data-get-html-message]').forEach((item) => {
        item.innerHTML = getMessage(item.dataset.getHtmlMessage, item);
    });
};

getAllTranslations();
import {sendGtagEvent} from "./gtag";
import {getValueFromBrowser} from "./trackingQueryHook";

export const showError = (field, errorMessage) => {
    field.innerHTML = errorMessage;
    field.classList.add('show');
};

export const hideError = (field) => {
    field.innerHTML = '';
    field.classList.remove('show');
};

export const handleError = (errorMessage, errorContainer) => {
    let field = '';
    if (errorContainer.classList.contains('signup-email')) {
        field = 'email';
    } else if (errorContainer.classList.contains('signup-phone')) {
        field = 'phone_number';
    } else if (errorContainer.classList.contains('signup-first-name')) {
        field = 'first_name';
    } else if (errorContainer.classList.contains('signup-last-name')) {
        field = 'last_name';
    } else if (errorContainer.classList.contains('signup-birth-date')) {
        field = 'dob';
    } else if (errorContainer.classList.contains('signup-coupon-code')) {
        field = 'coupon_code';
    }

    if (errorMessage) {
        showError(errorContainer, errorMessage);
        sendGtagEvent(`sign_up_form_${field}_invalid`, {
            affiliate_id: getValueFromBrowser('affiliateId') || '',
        });
    } else {
        hideError(errorContainer);
        sendGtagEvent(`sign_up_form_${field}_validated`, {
            affiliate_id: getValueFromBrowser('affiliateId') || '',
        });
    }
};

export const getEmailErrorMessage = (message) => {
    if (message.includes('duplicate')) {
        return 'Ya existe un usuario con este correo electrónico';
    } else if (message === "Invalid domain" || message.includes('black list')) {
        return 'Tu e-mail es incorrecto';
    } else {
        return '';
    }
};

export const getPhoneErrorMessage = (message) => {
    if (message.includes('duplicate')) {
        return 'Ya existe un usuario con este teléfono';
    } else {
        return '';
    }
};

import { PhoneNumberUtil } from "google-libphonenumber";
import { handleError } from "./errorHandler";
import { EMAIL_REG_EXP, MX_PHONE, NAME_REG_EXP } from './consts';
import { emailValidation, phoneValidation } from './validation';
import selectValidMessagesPassword from "./validate_password";
import validateBirthday from "./validate_birthday";

const signUpForm = document.getElementById("signup-form");
const inputsArr = [...signUpForm.querySelectorAll('.sign-up__input')];
const phoneNumberInstance = PhoneNumberUtil.getInstance();

const addErrors = (errorContainer) => {
	errorContainer?.classList.add('show');
}

const validateFirstStep = () => {
	const emailInput = document.getElementById('signup-email');
	const emailErrorContainer = signUpForm.querySelector(".error__message.signup-email");
	const phoneInput = document.getElementById('signup-phone');
	const phoneErrorContainer = signUpForm.querySelector(".error__message.signup-phone");
	const hintPasswordElements = signUpForm.querySelectorAll('.signup-hint-password');

	addErrors(emailErrorContainer);
	addErrors(phoneErrorContainer);

	inputsArr.forEach((input) => {
		input.addEventListener('input', (e) => {
			const startInputTime = sessionStorage.getItem("startInputTime"); // In every session we will have new time
			if (!startInputTime) {
				sessionStorage.setItem("startInputTime", Date.now().toString());
			}

			const attr = input.getAttribute('data-validation');
			
			if (attr === 'email') {
				const getEmailError = (email) => {
					if (email.length < 7) {
						return 'Mínimo 7 caracteres';
					}
					if (email.length > 50) {
						return 'Máximo 50 caracteres';
					}
					if (!email.match(EMAIL_REG_EXP)) {
						return 'Tu correo electrónico es incorrecto';
					}
					return '';
				};
				handleError(getEmailError(e.target.value), emailErrorContainer);
			}

			if (attr === 'password') {
				selectValidMessagesPassword(e.target.value, hintPasswordElements)
			}

			if (attr === 'phone') {
				const validatePhoneNumber = (phoneNumber) => {
					if (phoneNumber.length >= 6) {
						try {
							const phoneNumberWithCode = phoneNumberInstance
								.parseAndKeepRawInput(`+52${phoneNumber}`, '+52');
			
								if (
									!phoneNumber.match(MX_PHONE) ||
									!phoneNumberInstance.isValidNumber(phoneNumberWithCode)
								) {
									return 'El número de teléfono es incorrecto';
								}
							} catch (e) {
								return 'El número de teléfono es incorrecto';
							}
					} else {
						return 'El número de teléfono es incorrecto';
					}
					return '';
				}
				handleError(validatePhoneNumber(e.target.value), phoneErrorContainer);
			}
		});
	});

	const checkEmail = async (field) => {
		if (emailErrorContainer.innerHTML === '' && field.value.length > 0) {
			await emailValidation(field.value.trim(), emailErrorContainer);
		}
	}

	const checkPhone = async (field) => {
		if (phoneErrorContainer.innerHTML === '' && field.value.length > 0) {
			await phoneValidation(field.value, phoneErrorContainer);
		}
	};

	const onPhoneKeyPress = (e) => {
		const charStr = e.key.toString();
		if (!charStr.match(/^[0-9-]*$/)) {
			e.preventDefault()
		}
	}
	emailInput.addEventListener('blur', () => checkEmail(emailInput));
	phoneInput.addEventListener('blur', () => checkPhone(phoneInput));
	phoneInput.addEventListener('keypress', onPhoneKeyPress);

	return () => {
		emailInput.removeEventListener('blur', () => checkEmail(emailInput));
		phoneInput.removeEventListener('blur', () => checkPhone(phoneInput));
		phoneInput.removeEventListener('keypress', onPhoneKeyPress);
	};
}

const validateSecondStep = () => {
	const nameErrorContainer = signUpForm.querySelector(".error__message.signup-first-name");
	const surnameErrorContainer = signUpForm.querySelector(".error__message.signup-last-name");
	const couponErrorContainer = signUpForm.querySelector(".error__message.signup-coupon-code");
	const dayInput = signUpForm.querySelector("#screen-day");
	const dayErrorContainer = signUpForm.querySelector(".error-message.day-error");
	const monthInput = signUpForm.querySelector("#screen-month");
	const monthErrorContainer = signUpForm.querySelector(".error-message.month-error");
	const yearInput = signUpForm.querySelector("#screen-year");
	const yearErrorContainer = signUpForm.querySelector(".error-message.year-error");
	const dateErrorContainer = signUpForm.querySelector(".error__message.signup-dob");
	const selectHeadersArr = [...signUpForm.querySelectorAll('.signup__birthdate__row')];
	const selectListsArr = [...signUpForm.querySelectorAll('.select__list.js-select-list')];

	inputsArr.forEach((input) => {
		input.addEventListener('input',  (e) => {
			const attr = input.getAttribute('data-validation');
			const validateTextField = (value, errorContainer) => {
				const getTextFieldError = (value) => {
					if (value.length < 2) {
						return 'Mínimo 2 caracteres';
					}
					if (value.length > 50) {
						return 'Máximo 50 caracteres';
					}
					if (!value.match(NAME_REG_EXP)) {
						return 'Por favor, introduzca sólo el texto en latín o en español';
					}
					return '';
				};
				handleError(getTextFieldError(value), errorContainer);
			}
			
			if (attr === 'name') {
				validateTextField(e.target.value, nameErrorContainer);
			}

			if (attr === 'surname') {
				validateTextField(e.target.value, surnameErrorContainer);
			}

			if (attr === 'couponCode') {
				const getCouponError = (value) => {
					if (value.length > 20) {
						return 'Máximo 20 caracteres'
					}
					return '';
				};
				handleError(getCouponError(e.target.value), couponErrorContainer);
			}
		});
	});

	// need for revalidation if the user returned from the first step
	validateBirthday(
		dayInput.dataset.value, 
		monthInput.dataset.value, 
		yearInput.dataset.value,
		dayErrorContainer,
		monthErrorContainer,
		yearErrorContainer,
		dateErrorContainer,
	);
	
	[...selectHeadersArr, ...selectListsArr].forEach((item) => {
		item.addEventListener('click', () => {	
			const day = dayInput.dataset.value;
			const month = monthInput.dataset.value;
			const year = yearInput.dataset.value;
			validateBirthday(
				day,
				month,
				year,
				dayErrorContainer,
				monthErrorContainer,
				yearErrorContainer,
				dateErrorContainer,
			);
		})
	})
}

export { addErrors, validateFirstStep, validateSecondStep };

import {getValueFromBrowser} from "../utils/trackingQueryHook";
import {sendGtagEvent} from "../utils/gtag";

const handleGtagEvents = () => {
    document.querySelectorAll('[data-gtag]').forEach((gtagButton) => {
        gtagButton.addEventListener('click', () => {
            if (gtagButton.classList.contains('accordion__header') && !gtagButton.classList.contains('open')) {
                return;
            }
            if (gtagButton?.dataset?.gtag) {
                // On sign up button clicked event we need source id to be sent
                sendGtagEvent(gtagButton.dataset.gtag, {
                    affiliate_id: getValueFromBrowser('affiliateId') || '',
                    ...(gtagButton.dataset.gtag.includes("sign_up_button_clicked") && {
                        source_id: getValueFromBrowser('sourceId') || ''
                    }),
                });
            }
        });
    });
};

handleGtagEvents();
import common from "./common.translates";
import footer from "./footer.translates";
import header from "./header.translates";
import main from "./main.translates";
import popup from "./popup.translates";

const translations = {
    en: {
        ...common.en,
        ...footer.en,
        ...header.en,
        ...main.en,
        ...popup.en,
    },
    es: {
        ...common.es,
        ...footer.es,
        ...header.es,
        ...main.es,
        ...popup.es,
    }
};

export default translations;
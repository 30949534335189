// handle LiveChat widget open by support buttons
const handleOpenLiveChat = () => {
    document.querySelectorAll('[data-open-support]')?.forEach((button) => {
        button.addEventListener('click', () => {
            parent.LC_API.open_chat_window();
            return false;
        });
    });
}

handleOpenLiveChat();
import { NON_WHITESPACE_REG_EXP, RUSSIAN_SPANISH_WORD } from "./consts";
import {sendGtagEvent} from "./gtag";
import {getValueFromBrowser} from "./trackingQueryHook";

const validatePasswordField = (field, isValid, hasValue) => {
    if (isValid) {
        if (field.classList.contains('unsuccessful')) {
            field.classList.remove('unsuccessful');
        }
        field.classList.add('success');
        sendGtagEvent('sign_up_form_password_validated', {
            affiliate_id: getValueFromBrowser('affiliateId') || '',
        });
    } else {
        if (hasValue) {
            field.classList.add('unsuccessful');
        } else {
            if (field.classList.contains('success')) {
                field.classList.remove('success');
            }
            if (field.classList.contains('unsuccessful')) {
                field.classList.remove('unsuccessful');
            }
        }
        sendGtagEvent('sign_up_form_password_invalid', {
            affiliate_id: getValueFromBrowser('affiliateId') || '',
        });
    }
}

const isMinMaxLengthValid = (value) => (
    value?.length >= 8
    && value?.length <= 30
    && !RUSSIAN_SPANISH_WORD.test(value)
);

const includeCapitalLetter = (value) => (
    /[A-Z]/g.test(value)
);

const includeLowercaseLetter = (value) => (
    /[a-z]/g.test(value)
);

const includeNumber = (value) => (
    /\d/g.test(value)
);

const notIncludeSpaces = (value) => (
    NON_WHITESPACE_REG_EXP.test(value)
);

const selectValidMessagesPassword = (value, hints) => {
    validatePasswordField(hints[0], isMinMaxLengthValid(value), value.length);
    validatePasswordField(hints[1], notIncludeSpaces(value), value.length);
    validatePasswordField(hints[2], includeNumber(value), value.length);
    validatePasswordField(hints[3], includeCapitalLetter(value), value.length);
    validatePasswordField(hints[4], includeLowercaseLetter(value), value.length);
};

export default selectValidMessagesPassword;

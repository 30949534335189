import { SCREEN_WIDTH, WINPOT_CASINO_URL } from '../utils/consts';

const isResized = () => {
  return window.innerWidth <= SCREEN_WIDTH.DESKTOP_S;
}

const slider = document.querySelector('.slider');
const sliderWrapper = document.querySelector('.slider__wrapper');
const sliderWrapperLeft = document.querySelector('.slider__wrapper_left');
const sliderWrapperRight = document.querySelector('.slider__wrapper_right');
const uniqueSlidesQuantity = 3;
let slidesPerSwipe = isResized() ? 1 : 3;

let resize;
window.addEventListener('resize', () => {
  clearTimeout(resize);
  resize = setTimeout(() => slidesPerSwipe = isResized() ? 1 : 3, 100); 
});

if (sliderWrapper && slider) {
  const allSlides = Array.from(slider.children);
  const quantity = allSlides.length;
  slider.style.setProperty('--n', quantity);

  const onSlideClick = () => {
    window.open(`${WINPOT_CASINO_URL}promotions`, '_self')
  }

  allSlides.forEach((slide) => {
    slide.addEventListener('click', onSlideClick);
  });

  let x0 = null;
  let i = isResized() ? 2 : 1;
  let locked = false;

  const first = allSlides.filter((slide, index) => index >= 0 && index < uniqueSlidesQuantity && slide);
  const last = allSlides.filter((slide, index) => index < quantity && index >= quantity - uniqueSlidesQuantity && slide);

  const cloneSlides = (slidesArray, refElement) => {
    const allCurrentSlides = Array.from(slider.children);
    const clones = [];
    slidesArray.forEach((slide) => {
      clones.push(slide.cloneNode(true));
    })
    

    if (slidesArray === first) {
      clones.forEach((slide) => {
        slider.append(slide);
        slide.addEventListener('click', onSlideClick);
      })
      for (let i = 0; i < uniqueSlidesQuantity; i++) {
        slider.removeChild(allCurrentSlides[i]);
      }
    } else if (slidesArray === last) {
      clones.forEach((slide) => {
        slider.insertBefore(slide, refElement);
        slide.addEventListener('click', onSlideClick);
      })
      for (let i = quantity - 1; i >= quantity - uniqueSlidesQuantity; i--) {
        slider.removeChild(allCurrentSlides[i]);
      }
    }
  }

  const unify = (event) => { 
    return event.changedTouches ? event.changedTouches[0] : event 
  };

  const lock = (event) => {
    x0 = unify(event).clientX;
    slider.classList.toggle('smooth', !(locked = true));
  }

  const move = (event) => {
    const setProperties = (name, value) => {
      sliderWrapper.style.setProperty(name, value);
      sliderWrapperLeft.style.setProperty(name, value);
      sliderWrapperRight.style.setProperty(name, value);
      slider.style.setProperty(name, value);
    }

    const onSliderEnd = (iMin, iMax) => {
      if (i === iMin) {
        setTimeout(() => {
          cloneSlides(last, slider.firstElementChild);
          slider.classList.remove('smooth');
          i = iMin + uniqueSlidesQuantity / slidesPerSwipe;
          setProperties('--i', i);
        }, 500)
      }
  
      if (i === iMax) {
        setTimeout(() => {
          cloneSlides(first, null);
          slider.classList.remove('smooth');
          i = iMax - uniqueSlidesQuantity / slidesPerSwipe;
          setProperties('--i', i);
        }, 500)
      }
    }

    if (locked) {
      let dx = unify(event).clientX - x0, s = Math.sign(dx);
      if ((i > 0 || s < 0) && (i < quantity / slidesPerSwipe - 1 || s > 0)) {
        i -= s;
        setProperties('--i', i);
      }
    }

    x0 = null;
    slider.style.setProperty('--tx', '0px');
    slider.classList.toggle('smooth', !(locked = false));

    if (isResized()) {
      onSliderEnd(1, quantity / slidesPerSwipe - 2);
    } else {
      onSliderEnd(0, quantity / slidesPerSwipe - 1);
    }
  } 

  const drag = (event) => {
    event.preventDefault();

    if (locked)  {
      slider.style.setProperty('--tx', `${Math.round(unify(event).clientX - x0)}px`);
      event.target.parentElement.removeEventListener('click', onSlideClick);
      setTimeout(() => event.target.parentElement.addEventListener('click', onSlideClick), 500);
    }
  };

  slider.addEventListener('mousemove', (event) => drag(event), false);
  slider.addEventListener('touchmove', (event) => drag(event), {passive: true});

  slider.addEventListener('mousedown', (event) => lock(event), false);
  slider.addEventListener('touchstart', (event) => lock(event), {passive: true});

  slider.addEventListener('mouseup', (event) => move(event), false);
  slider.addEventListener('touchend', (event) => move(event), {passive: true});
}

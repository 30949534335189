import {AffiliateProduct, getValueFromBrowser, setValueToBrowser} from "./trackingQueryHook.js";
import {APP_SERVER_URL, APP_WINPOT_CASINO} from "../../../config";

const getSiteConfig = async (type) => {
  const params = new URLSearchParams({ type });
  try {
    const response = await fetch(`${APP_SERVER_URL}site/config?${params}`);
    return await response.json();
  } catch (error) {
    throw new Error('Error:', error);
  }
};

export const checkIsSportUser = () => {
	const userProduct =
		+getValueFromBrowser('product') ||
		+getValueFromBrowser('product_1') ||
		+getValueFromBrowser('product_2') ||
		1;
	return userProduct === AffiliateProduct.Sport;
};

export const openAppLink =  (needLinkForIOSApp = true) => {
	const isSportUser = checkIsSportUser();
	if (needLinkForIOSApp) {
		const link = isSportUser
			? 'https://apps.apple.com/mx/app/winpot-sport/id6446956929'
			: 'https://apps.apple.com/mx/app/winpot-casino/id1632635281';
        window.open(link, '_blank');
	} else {
      // localStorage.setItem('isAppPanelVisible', Date.now().toString()); Mobile App Banner is unused on home page
      window.location.href = `${APP_WINPOT_CASINO}app`;
	}
};

import {WINPOT_CASINO_URL} from "../utils/consts";
import {AddOrRemoveSpinner} from "../utils/utils";

const handleClickOnLink = (linkHref) => {
    if(linkHref.includes(WINPOT_CASINO_URL)) {
        AddOrRemoveSpinner(true);
    }
}

const links = document.getElementsByTagName('a');
for(const link of links) {
    const linkHref = link.href;;
    link.addEventListener('click', (e) => {
        handleClickOnLink(linkHref);
    });
}
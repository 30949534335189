import { getMobileOperatingSystem } from "../utils/utils";
import { imagesKeys } from "../data/modules/images_keys";

const getImageSrc = (key) => {
    if (!imagesKeys.hasOwnProperty(key)) {
        return ''
    }
    if (key.includes('app')) {
        return (getMobileOperatingSystem() === 'iOS' || getMobileOperatingSystem() === 'MacOS')
            ? imagesKeys[key]['ios']
            : imagesKeys[key]['android'];
    }
    const isLogo = key.includes('logo');
    const breakpoint = isLogo ? 320 : 899;
    return imagesKeys[key][window.innerWidth <= breakpoint ? 'small' : 'large'];
};

export default getImageSrc;

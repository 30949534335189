import {APP_SERVER_URL} from "../../../config";

const RESET_METHOD_ENUM = {
    email: 'email',
    phone: 'phone'
};

const PHONE_REG_EXP = /^[0-9]*$/;
const EMAIL_REG_EXP =
    /^([a-zA-Z0-9ЁёáéíñóúüÁÉÍÑÓÚÜ]+([._+-]?[a-zA-Z0-9ЁёáéíñóúüÁÉÍÑÓÚÜ])+)@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+\.[a-zA-Z]{2,})$/;

const SMSCodeWatcher = () => {
    console.log("'OTPCredential' in window", 'OTPCredential' in window);
    if ('OTPCredential' in window) {
        window.addEventListener('DOMContentLoaded', (e) => {
            const input = document.querySelector(
                'input[autocomplete="one-time-code"]'
            );
            if (!input) return;
            // Cancel the WebOTP API if the form is submitted manually.
            const ac = new AbortController();
            const form = input.closest('form');

            if (form) {
                form.addEventListener('submit', (e) => {
                    // Cancel the WebOTP API.
                    ac.abort();
                });
            }
            // Invoke the WebOTP API
            navigator.credentials
                .get({
                    // @ts-ignore
                    otp: {transport: ['sms']},
                    signal: ac.signal,
                })
                .then((otp) => {
                    input.value = otp.code;
                    // Automatically submit the form when an OTP is obtained.
                    if (form) form.submit();
                })
                .catch((err) => {
                });
        });
    }
};

const startUserSMSTimer = (changeCurrentPopup) => {
    SMSCodeWatcher();

    let timer = 60 * 2, // 2 min
        minutes,
        seconds;

    const display = document.getElementById('check__user__sms__timer');
    display.innerHTML = '02:00';

    const interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
            clearInterval(interval);
            display.innerHTML = 'Tiempo De Espera. <button id="OMT">Reenviar OTP</button>';
            document.getElementById('OMT').addEventListener('click', () => {
                checkResetPassMethod({
                    data: `${document.getElementById('forgotPass__user__phone')?.innerHTML}`,
                    inputField: null,
                    errorField: null,
                    submitButton: null,
                    changeCurrentPopup
                });
            })
        }
    }, 1000);
};

const checkResetPassMethod = (
    data,
    inputField,
    errorField,
    submitButton,
    changeCurrentPopup
) => {
    let dataValue = '';
    if (data?.target[0]) {
        data?.preventDefault();
        dataValue = data?.target[0]?.value;
    } else {
        dataValue = data;
    }

    const productionDomain = window.location.hostname.includes(".winpot.mx") || window.location.hostname === "winpot.mx";

    const requestData = {};
    requestData.origin = productionDomain ? "winpot.mx" : "stage-winpot.mx";

    let currentMethod = RESET_METHOD_ENUM.email;

    if (dataValue.match(PHONE_REG_EXP)) {
        currentMethod = RESET_METHOD_ENUM.phone;
        requestData.phone = `+52${dataValue.trim()}`;
        document.getElementById('forgotPass__user__phone').innerHTML = requestData.phone;
    } else {
        if (dataValue.match(EMAIL_REG_EXP)) {
            requestData.email = dataValue;
        } else {
            requestData.nickName = dataValue;
        }
    }

    const currentRoute = currentMethod === RESET_METHOD_ENUM.phone
        ? 'player/crm/password/reset/phone'
        : 'player/crm/password/reset'
    fetch(`${APP_SERVER_URL}${currentRoute}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
    })
        .then((response) => {
            if (!response.ok) {
                // If response is not ok, parse the response to get the error details
                return response.json().then(error => {
                    return Promise.reject(error);
                });
            }
            return response.json();
        })
        .then((response) => {
            if (inputField) {
                inputField.value = '';
                inputField.classList.remove('error');
            }
            if (errorField) {
                errorField.innerHTML = '';
            }
            startUserSMSTimer(changeCurrentPopup);
            changeCurrentPopup && changeCurrentPopup(submitButton, 'data-open-popup-after');
        })
        .catch((e) => {
            const errorMessage = e?.message || "";
            switch (errorMessage) {
                case 'incorrect.credentials':
                    if (inputField) {
                        inputField.classList.add('error');
                    }
                    if (submitButton) {
                        submitButton.disabled = true;
                    }
                    if (errorField) {
                        errorField.innerHTML = (currentMethod === RESET_METHOD_ENUM.phone)
                            ? 'El número de teléfono es incorrecto'
                            : 'La cuenta con ese correo electrónico no existe';
                    }
                    break;
                case 'phone.not.verified':
                    changeCurrentPopup && changeCurrentPopup(submitButton, 'data-phone-incorrect');
                    break;
                case 'limit.password.reset':
                    changeCurrentPopup && changeCurrentPopup(submitButton, 'data-phone-attempts');
                    break;
                default:
                    if (errorField) {
                        errorField.innerHTML = 'Error desconocido';
                    }
            }
        })
};

const confirmSMSCode = async (
    errorField,
    code,
    currentPhoneNumber,
    changeCurrentPopup
) => {
    const requestData = {
        phone: currentPhoneNumber,
        confirmationCode: code,
    };

    fetch(`${APP_SERVER_URL}player/crm/password/reset/confirm-sms-code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
    })
        .then((response) => {
            if (!response.ok) {
                // If response is not ok, parse the response to get the error details
                return response.json().then(error => {
                    return Promise.reject(error);
                });
            }
            return response.json();
        })
        .then((response) => {
            errorField.innerHTML = '';
            changeCurrentPopup(null, 'forgot-pass-reset-popup');
            document.getElementById('confirmation__code').innerHTML = response?.code || '';
        })
        .catch((e) => {
            const errorMessage = e?.message || "";
            switch (errorMessage) {
                case 'invalid.confirmation.code':
                    errorField.innerHTML = 'Se ingresó una OTP no válida';
                    document.getElementById('check__user__sms__form').querySelector('.input__line').classList.add('error');
                    break;
                default:
                    if (errorField) {
                        errorField.innerHTML = 'Error desconocido';
                    }
            }
        })
}

const resetPass = (
    confirmationCode,
    newPassCheckInput,
    newPassCheckError,
    submitButton,
    changeCurrentPopup
) => {
    fetch(`${APP_SERVER_URL}player/crm/password/confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: newPassCheckInput.value,
                confirmationCode: confirmationCode,
            })
        }
    )
        .then((response) => {
            if (!response.ok) {
                // If response is not ok, parse the response to get the error details
                return response.json().then(error => {
                    return Promise.reject(error);
                });
            }
            return response.json();
        })
        .then((response) => {
            newPassCheckError.innerHTML = '';
            changeCurrentPopup && changeCurrentPopup(submitButton, 'data-open-popup-after');
        })
        .catch((e) => {
            const errorMessage = e?.message || "";
            switch (errorMessage) {
                case 'incorrect.credentials':
                    newPassCheckInput.classList.add('error');
                    newPassCheckError.innerHTML = 'La cuenta no existe o no se encuentra';
                    break;
                case 'expired.confirmation.code':
                    newPassCheckInput.classList.add('error');
                    newPassCheckError.innerHTML = 'El enlace ha caducado. Para cambiar la contraseña, vaya al formulario \'¿Ha olvidado su contraseña?\'';
                    break;
                case 'invalid.confirmation.code':
                    newPassCheckInput.classList.add('error');
                    newPassCheckError.innerHTML = 'Código de confirmación inválido';
                    break;
                case 'equal.passwords':
                    newPassCheckInput.classList.add('error');
                    newPassCheckError.innerHTML = 'La nueva contraseña no puede ser igual a la actual';
                    break;
                default:
                    if (newPassCheckError) {
                        newPassCheckError.innerHTML = 'Error desconocido';
                    }
            }
        });
};


export {
    checkResetPassMethod,
    confirmSMSCode,
    resetPass,
};
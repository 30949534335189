const handlePasswordVisibility = () => {
    document.querySelectorAll('[data-view-password]').forEach((viewPassButton) => {
        viewPassButton.addEventListener('click', (e) => {
            e.stopPropagation();
            if (e.currentTarget.previousElementSibling?.type === 'password') {
                e.currentTarget.classList.remove('hide__password');
                e.currentTarget.classList.add('show__password');
                e.currentTarget.previousElementSibling.type = 'text';
            } else if (e.currentTarget.previousElementSibling.type === 'text') {
                e.currentTarget.classList.add('hide__password');
                e.currentTarget.classList.remove('show__password');
                e.currentTarget.previousElementSibling.type = 'password';
            }
        });
    });
};

handlePasswordVisibility();
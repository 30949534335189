const addOnClickCouponCode = () => {
    const couponCodeButton = document.getElementById('couponButton');
    couponCodeButton?.addEventListener('click', () => {
        const couponWrapper = document.getElementById('couponWrapper');
        if (couponWrapper.classList.contains('is-active')) {
            couponWrapper.classList.remove('is-active');
        } else {
            couponWrapper.classList.add('is-active');
        }
    });
}

addOnClickCouponCode();